<template>
	<div>
		<div>
			<a-button type="primary" icon="plus" @click="add">新增</a-button>
			<a-button type="primary" icon="edit" style="margin: 0 2px;" @click="edit">编辑</a-button>
			<a-button type="danger" icon="delete" @click="del">删除</a-button>
		</div>
		<a-alert type="success" style="margin:5px 5px 5px 0;" >
      <template slot="message">
        <div>
          <span>{{ `当前选择：${message}` }}</span>
          <a
            v-if="message"
            @click="onClearSelected"
            style="margin-left: 10px;color:#3853db"
          >取消选择</a>
        </div>
      </template>
    </a-alert>
		<div style="height:75vh;overflow:hidden;">
      <div style="height:100%;overflow:auto;">
        <a-tree
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          :replaceFields="{children:'children', title:'categoryName', key:'id' }"
          @expand="onExpand"
          @select="onSelect"
        />
      </div>
    </div>

		<addLayer
      ref="addLayer"
      :comtreeData="treeData"
      :selectedKeys="selectedKeys"
      @on-ok="addTree"
    />
	</div>
</template>

<script>
import addLayer from './combinationAddLayer.vue'
import api from '../api.js'
export default {
  components: {
    addLayer
  },
  data () {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      treeData: [],
      message: '',
      selectTreeData: {}
    }
  },
  watch: {
  },
  mounted () {
    this.getTree()
  },
  methods: {
    async getTree () {
      const res = await api.getCompTree()
      this.treeData = res.data
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect (selectedKeys, info) {
      if (info.selected) {
        this.message = info.node.title
        this.selectedKeys = selectedKeys
        this.selectTreeData = info.selectedNodes[0].data.props
        this.$emit('select', this.selectTreeData)
      } else {
        this.message = ''
        this.selectedKeys = []
        this.$emit('select', {})
      }
    },

    onClearSelected () {
      this.message = ''
      this.selectedKeys = []
      this.$emit('select', {})
    },
    //
    add () {
      // if (this.selectedKeys.length <= 0) {
      //   this.$message.error('请选择节点')
      //   return
      // }
      this.$refs.addLayer.open()
    },
    edit () {
      if (this.selectedKeys.length <= 0) {
        this.$message.error('请选择节点')
        return
      }
      this.$refs.addLayer.open(this.selectTreeData)
    },
    del () {
      if (this.selectedKeys.length <= 0) {
        this.$message.error('请选择节点')
        return
      }
      this.$confirm({
        title: '确认删除吗?',
        onOk: async () => {
          await api.delCompTree({ id: this.selectedKeys[0] })
          this.getTree()
          this.message = ''
          this.selectedKeys = []
        },
        onCancel () {}
      })
    },
    addTree (value) {
      this.getTree()
      this.message = value.categoryName
      this.selectTreeData = { ...value }
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar{ /*滚动条整体样式*/
	width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}
::-webkit-scrollbar-thumb{ /*滚动条里面小方块*/
	border-radius: 10px;
	background: #cfcbcb;
}
::-webkit-scrollbar-track{ /*滚动条里面轨道*/
	border-radius: 10px;
	background: #ededed;
}
</style>
