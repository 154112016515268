<template>
	<div>
		<a-row :gutter="10">
      <a-col :span="6">
        <layerTree @select="treeSelect"></layerTree>
      </a-col>
      <a-col :span="18">
        <ax-table
					ref="roleTable"
					:show-search="true"
					:searchForm="searchForm"
					:searchActions='searchActions'
					:columns="columns"
					:dataSourceApi="api.getShpList"
					:dataSourceParams="dataSourceParams"
					:sqlParams="sqlParams"
					:clickable="false"
					:scroll="{ y: '55vh', x: '80vw' }"
				>
				</ax-table>

      </a-col>
    </a-row>
	</div>
</template>

<script>
import layerTree from '../component/combinationLayerTree.vue'
import api from '../api.js'
const searchForm = [
  { label: '文件名称', type: 'input', model: 'name', options: {}, formItem: {}, col: { span: 6 } }
]
export default {
  components: {
    layerTree
  },
  data () {
    return {
      api,
      dataSourceParams: {
        groupId: ''
      },
      categoryId: '',
      sqlParams: { like: ['name'] },
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' }
      ],
      searchForm: this.$common.initGridFormData(searchForm, { layout: 'horizontal', labelWidth: 80 }),
      columns: this.$ax.tools.initColumn([
        {
          title: '文件名称',
          dataIndex: 'name'
        },
        {
          title: '说明',
          dataIndex: 'remarks'
        },
        {
          title: '图层类型',
          dataIndex: 'type_dictText'
        },
        {
          title: '年份',
          dataIndex: 'yearTime'
        }
      ], true)
    }
  },
  methods: {
    // tree 选择
    treeSelect (data) {
      this.categoryId = data.id
      this.dataSourceParams.categoryId = data.id
      this.$refs.roleTable.getDataSource({ groupId: data.id })
    },
    // 图层添加
    handleOk () {
      this.$refs.roleTable.getDataSource({ groupId: this.categoryId })
    }
  }
}
</script>

<style>

</style>
