<template>
	<a-modal width="50%" title="添加" :visible="visible" @ok="handleOk"
		@cancel="handleCancel">
			<ax-form ref="formBox" :formBuilder="formBuilder"
      @change="onValueChange"
			></ax-form>
	</a-modal>
</template>

<script>
import { initGridFormData } from '@/common/tools'
import { getByDicKind } from '@/common/utils'
import api from '../api.js'
export default {
  props: {
    comtreeData: {
      type: Array,
      default: () => ([])
    },
    selectedKeys: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      visible: false,
      formBuilder: initGridFormData([
        {
          label: '图层名称',
          type: 'input',
          model: 'categoryName',
          col: { span: 24 },
          rules: [{ required: true, message: '菜单名称不能为空' }]
        },
        {
          label: '上级菜单',
          type: 'treeSelect',
          model: 'parentId',
          options: {
            placeholder: '不选默认为顶层',
            treeData: [],
            replaceFields: { children: 'children', title: 'categoryName', key: 'id', value: 'id' }
            // disabled: true
          },
          col: { span: 24 }
        },
        {
          label: '类型',
          type: 'select',
          model: 'groupType',
          options: { options: [] },
          col: { span: 24 },
          rules: [{ required: true, message: '菜单类型不能为空' }]
        },
        // {
        //   label: '图层类型',
        //   type: 'select',
        //   model: 'shpType',
        //   options: { options: [] },
        //   col: { span: 24 }
        // },
        {
          label: '关联基础图层',
          type: 'treeSelect',
          model: 'categoryIds',
          options: {
            options: [],
            replaceFields: { children: 'children', title: 'categoryName', key: 'id', value: 'id' },
            treeCheckable: true,
            multiple: true
          },
          col: { span: 24 }
        },
        {
          label: '说明',
          type: 'textarea',
          model: 'remarks',
          options: { minRows: 4, maxRows: 4 },
          col: { span: 24 }
        }
      ])
    }
  },
  mounted () {
  },
  methods: {
    onValueChange (value, key) {
      console.log(value, key, 'ley')
    },

    open (params) {
      this.visible = true
      setTimeout(async () => {
        this.getTypeKind()
        if (this.selectedKeys && this.selectedKeys.length > 0) {
          this.$refs.formBox.setFieldsValue({ parentId: this.selectedKeys[0] })
        }

        const res = await api.getTree()
        this.$refs.formBox.setOptions(['parentId', 'categoryIds'], 'treeData', res.data)
        this.$refs.formBox.setOptions(['parentId'], 'treeData', this.comtreeData)

        // if (!params) {
        //   this.$refs.formBox.setFieldsValue({ shpType: '01' })
        // }

        if (params) {
          this.$refs.formBox.setFieldsValue({
            ...params,
            categoryIds: params.categoryIds && params.categoryIds.join(','),
            parentId: params.parentId === '0' ? '' : params.parentId
          })
        }
      }, 500)
    },

    // 获取类型
    async getTypeKind () {
      const data = await getByDicKind('group_type')
      this.$refs.formBox.setOptions(['groupType'], 'options', data)

      // const datas = await getByDicKind('shp_type')
      // this.$refs.formBox.setOptions(['shpType'], 'options', datas)
    },

    handleOk () {
      this.$refs.formBox.form.validateFields(async (errors, values) => {
        if (!errors) {
          const value = this.$refs.formBox.getFieldsValue()

          value.categoryIds = (value.categoryIds && value.categoryIds.split(',')) || []

          const res = await api.addCompTree({ ...value })
          if (res.status === 200) {
            this.$emit('on-ok', value)
            this.handleCancel()
          }
        }
      })
    },
    handleCancel () {
      const data = this.$refs.formBox.getFieldsValue()
      Object.keys(data).forEach(item => {
        data[item] = ''
      })
      this.$refs.formBox.setFieldsValue(data)
      this.visible = false
    }
  }
}
</script>

<style>

</style>
